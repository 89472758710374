import Settings from '../domain/Settings';
import {hasPurposesConsent, hasVendorsConsent} from '../services/privacyService';
import * as storage from '../utils/storage';
import {getGigyaId} from '../utils/gigya';
import {getCxenseProperties} from '../services/cxenseService';
import * as performance from '../utils/performance';
import {loadResource, ResourceType} from '../utils/resource-util';

declare global {
	interface Window {
		firstid?: {
			customData?: FirstIdCustomData;
		};
	}
}

type FirstIdCustomData = {
	cxenseId?: string;
	pwdId?: string;
	accountId?: string;
};

let identifiers: FirstIdCustomData;

export function init(): void {
	const settings = Settings.getInstance();

	if (!settings.features?.firstId)
		return;

	if (!hasConsent())
		return;

	identifiers = getIdentifiers();

	if (!hasSufficientIdentifier())
		return;

	performance.mark('firstid - start');

	document.removeEventListener('firstid:initialize', onFirstIdInit);
	document.addEventListener('firstid:initialize', onFirstIdInit);

	loadResource({
		type: ResourceType.Script,
		content: 'https://cdn.preprod.first-id.fr/sdk/loader/loader-flex.min.js'
	});
}

function onFirstIdInit() {
	performance.mark('firstid - init');

	window.firstid = {
		customData: identifiers,
	};
}

function hasSufficientIdentifier(): boolean {
	return !!identifiers?.cxenseId;
}

function hasConsent(): boolean {
	return hasPurposesConsent([1, 2, 3, 4, 7, 9, 10])
		&& hasVendorsConsent([1178]);
}

function getIdentifiers(): FirstIdCustomData {
	return Object.fromEntries(
		Object.entries({
			pwdId: storage.getFromCookie('authId'),
			accountId: getGigyaId(),
			cxenseId: getCxenseProperties()?.id
		}).filter(([, v]) => v)
	);
}
